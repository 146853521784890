import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import { ReactComponent as ApprovedSvg } from "../assets/svg/approved.svg";
import { ReactComponent as InfoSvg } from "../assets/svg/info-info.svg";
import { ReactComponent as PhoneSvg } from "../assets/svg/phone-info.svg";
import { ReactComponent as RatesSvg } from "../assets/svg/rates-info.svg";
import { ReactComponent as ServicesSvg } from "../assets/svg/services-info.svg";
import { ReactComponent as UserSvg } from "../assets/svg/user-info.svg";
import { ReactComponent as InfoRightArrowSvg } from "../assets/svg/arrow-info.svg";
import { ReactComponent as InfoDownArrowSvg } from "../assets/svg/arrow-down-info.svg";
import { ReactComponent as WhatsAppSvg } from "../assets/svg/whats-app.svg";
import { ReactComponent as TelegramSvg } from "../assets/svg/telegram.svg";

import { useParams } from "react-router-dom";
import { BASE_URL, http } from "../helpers/http.helper";
import { Helmet } from "react-helmet";
import CommonTopBar from "../components/layouts/CommonTopBar";

const SingleEscort = () => {
  const { name } = useParams();
  const [expandAbout, setExpandAbout] = useState(false);
  const [expandInfor, setExpandInfo] = useState(false);
  const [expandServices, setExpandServices] = useState(false);
  const [expandLanguages, setExpandLanguages] = useState(false);
  const [expandRates, setExpandRates] = useState(false);
  const [expandContact, setExpandContact] = useState(false);
  const [mainImageURL, setMainImageURL] = useState("");

  const [screenSize, setScreenSize] = useState(false);

  const [ad, setAd] = useState(false);
  const [images, setImages] = useState([]);

  const modifiedName = name
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  const setWhatsappNumberClicks = async () => {
    try {
      await http.patch(`/pub/set-whatsapp-clicks/${ad._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  const setTelegramNumberClicks = async () => {
    try {
      await http.patch(`/pub/set-telegram-clicks/${ad._id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 575) {
        setScreenSize(true);
      } else {
        setScreenSize(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const getAd = async () => {
    try {
      const { data } = await http.get(`/pub/escort-ad/${modifiedName}`);
      const ad = data.ad;

      await http.patch(`/pub/set-view/${ad._id}`);

      const imgArr = [];

      // eslint-disable-next-line array-callback-return
      ad.images.map((image) => {
        imgArr.push({
          original: `${BASE_URL}/img/${image}`,
          thumbnail: `${BASE_URL}/img/${image}`,
        });
      });

      setMainImageURL(`${BASE_URL}/img/${ad.mainImage}`);

      imgArr.unshift({
        original: `${BASE_URL}/img/${ad.mainImage}`,
        thumbnail: `${BASE_URL}/img/${ad.mainImage}`,
      });

      setImages(imgArr);
      setAd(ad);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAd();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`${ad.name} - ${ad.country}`} </title>
        <meta name="title" content={`${ad.metaTitle}`} />
        <meta name="keywords" content={`${ad.metaKeywords}`} />
        <meta name="description" content={`${ad.metaDescription || ad.description} privé.xyz`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`${ad.metaTitle}`} />
        <meta
          property="og:description"
          content={`${ad.metaDescription || ad.description} privé.xyz`}
        />
        <meta property="og:image" content={mainImageURL} />
        <meta property="og:url" content={`https://privé.xyz/single-escort/${ad._id}`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`${ad.metaTitle}`} />
        <meta
          name="twitter:description"
          content={`${ad.metaDescription || ad.description} privé.xyz`}
        />
        <meta name="twitter:image" content={mainImageURL} />
        <meta name="twitter:url" content={`https://privé.xyz/single-escort/${ad._id}`} />

        <link rel="canonical" href={`https://privé.xyz/single-escort/${ad._id}`} />
      </Helmet>

      <CommonTopBar />

      <div className="top-bar-single-page-content">
        <h1>
          {ad.name} <ApprovedSvg />
        </h1>
      </div>

      <div className="single-page-wrapper">
        <div className="single-page-content">
          <div
            class="escort-card-container partners"
            style={{ display: "flex", width: "100%", flexWrap: "wrap" }}
          >
            <div class="partner-card" style={{ padding: "20px" }}>
              <a
                href="https://www.eurogirlsescort.com"
                target="_blank"
                rel="dofollow noreferrer"
                title="EuroGirlsEscort.com"
              >
                <img
                  src="https://www.eurogirlsescort.com/dist/images/banners/468X60.jpg"
                  alt="EuroGirlsEscort.com"
                  title="EuroGirlsEscort.com"
                />
              </a>
            </div>
            <div class="partner-card" style={{ padding: "20px" }}>
              <div
                style={{
                  padding: 0,
                  margin: 0,
                  overflow: "hidden",
                  fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                  fontWeight: "bold",
                  width: "244px",
                  height: "60px",
                  display: "block",
                  position: "relative",
                  color: "black",
                  backgroundColor: "black",
                }}
              >
                <a
                  target="_blank"
                  href="https://massagerepublic.com/female-escorts-in-dubai/pornstar-anastasia-doll-french"
                  title="Porn Star Anastasia Doll"
                  style={{
                    padding: 0,
                    margin: 0,
                    overflow: "hidden",
                    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                  }}
                >
                  <img
                    src="//static.massagerepublic.com/b/mr_banner244x60.jpg"
                    alt="Dubai Escorts - Massage Republic"
                    style={{
                      padding: 0,
                      margin: 0,
                      overflow: "hidden",
                      display: "block",
                      position: "relative",
                      width: "244px",
                      height: "60px",
                      backgroundColor: "black", // Removed !important
                    }}
                  />
                  <span
                    style={{
                      padding: "0",
                      margin: "0",
                      overflow: "hidden",
                      fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                      fontWeight: "bold",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      textDecoration: "none",
                      width: "150px",
                      textAlign: "center",
                      lineHeight: "1.2em",
                      display: "block",
                      color: "black !important",
                      fontSize: "24px !important",
                    }}
                  >
                    Featured Escort
                  </span>
                </a>
                <a
                  target="_blank"
                  href="https://massagerepublic.com/female-escorts-in-dubai"
                  title="Shemales Dubai"
                  style={{
                    padding: "0",
                    margin: "0",
                    overflow: "hidden",
                    fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                    textShadow: "0 1px 1px rgba(0,0,0,0.8)",
                    position: "absolute",
                    textDecoration: "underline",
                    right: "6px",
                    bottom: "12px",
                    lineHeight: "1",
                    fontWeight: "normal",
                    width: "86px",
                    textAlign: "right",
                    color: "white", // "!important" is not supported in inline styles
                    fontSize: "12px",
                  }}
                >
                  Dubai escorts
                </a>
              </div>
            </div>
          </div>

          {/* <div className="previous-next-btton">
            <button>
              <LeftButton />
              previous model
            </button>
            <button>
              next model
              <RightButton />
            </button>
          </div> */}

          <div className="left-container">
            <div className="image-gallery-container">
              <ImageGallery showFullscreenButton={false} showPlayButton={false} items={images} />
            </div>
          </div>

          <div className="right-container">
            {/* <div className="escort-details-container">
              <h1>
                {ad.name} <ApprovedSvg />
              </h1>
              <span>{ad.city}</span>
              <span className="views">
                <ViewsSvg /> {ad.views}
              </span>
            </div> */}

            <div className="description-container">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandAbout(!expandAbout)}
              >
                <i>
                  <InfoSvg />
                </i>
                <span className="top-section-header">About</span>
                {!expandAbout ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <p
                style={{
                  display: !expandAbout && screenSize ? "none" : "block",
                }}
              >
                <pre>{ad.description}</pre>
              </p>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandInfo(!expandInfor)}
              >
                <i>
                  <UserSvg />
                </i>
                <span className="top-section-header">Details</span>
                {!expandInfor ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              {ad && (
                <div
                  className="details-table-grid-wrapper"
                  style={{
                    display: !expandInfor && screenSize ? "none" : "flex",
                  }}
                >
                  <div className="details-table-grid">
                    {ad.age && (
                      <div className="details-table-row">
                        <span className="heading">Age</span>
                        <span className="data">{ad.age}</span>
                      </div>
                    )}

                    {ad.ethnicity && (
                      <div className="details-table-row">
                        <span className="heading">Ethnicity</span>
                        <span className="data">{ad.ethnicity}</span>
                      </div>
                    )}

                    {ad.nationality && (
                      <div className="details-table-row">
                        <span className="heading">Nationality</span>
                        <span className="data">{ad.nationality}</span>
                      </div>
                    )}

                    {ad.availability && (
                      <div className="details-table-row">
                        <span className="heading">Availability</span>
                        <span className="data">{ad.availability}</span>
                      </div>
                    )}

                    {ad.height && (
                      <div className="details-table-row">
                        <span className="heading">Height</span>
                        <span className="data">{ad.height}cm</span>
                      </div>
                    )}

                    {ad.website && (
                      <div className="details-table-row">
                        <span className="heading">Website</span>
                        <span className="data">
                          <a href={ad.website}>{ad.website}</a>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandServices(!expandServices)}
              >
                <i>
                  <ServicesSvg />
                </i>
                <span className="top-section-header">Services</span>
                {!expandServices ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="service-table"
                style={{
                  display: !expandServices && screenSize ? "none" : "flex",
                }}
              >
                {ad && ad.services.map((service) => <div>{service}</div>)}
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandLanguages(!expandLanguages)}
              >
                <i>
                  <ServicesSvg />
                </i>
                <span className="top-section-header">Languages</span>
                {!expandLanguages ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="service-table"
                style={{
                  display: !expandLanguages && screenSize ? "none" : "flex",
                }}
              >
                {ad && ad.languages.map((language) => <div>{language}</div>)}
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandRates(!expandRates)}
              >
                <i>
                  <RatesSvg />
                </i>
                <span className="top-section-header">Rates</span>
                {!expandRates ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="details-table-grid-wrapper"
                style={{
                  display: !expandRates && screenSize ? "none" : "flex",
                }}
              >
                <div className="details-table-grid">
                  {ad &&
                    ad.rates &&
                    ad.rates.map((rate) => (
                      <div className="details-table-row">
                        <span className="heading">{rate.hours} hour</span>
                        <span className="data">{rate.amount} AED</span>
                      </div>
                    ))}
                </div>
              </div>
            </div>

            <div className="details-table">
              <button
                className="top-section-details"
                onClick={() => screenSize && setExpandContact(!expandContact)}
              >
                <i>
                  <PhoneSvg />
                </i>
                <span className="top-section-header">Contact</span>
                {!expandContact ? <InfoRightArrowSvg /> : <InfoDownArrowSvg />}
              </button>

              <div
                className="button-wrapper"
                style={{
                  display: !expandContact && screenSize ? "none" : "flex",
                }}
              >
                {ad && ad.isOnWhatsApp && (
                  <a
                    href={`https://wa.me/${ad.phoneNumber.replace(/\s/g, "")}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={setWhatsappNumberClicks}
                  >
                    <WhatsAppSvg />
                  </a>
                )}
                {ad && ad.isOnTelegram && (
                  <a
                    href={`https://t.me/${ad.phoneNumber.replace(/\s/g, "")}`}
                    target="_blank"
                    rel="noreferrer"
                    onClick={setTelegramNumberClicks}
                  >
                    <TelegramSvg />
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleEscort;
