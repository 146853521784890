import React from "react";
import { DropdownWithCheckbox, FilterWrapper } from "../components";
import EscortCard from "../components/cards/EscortCard";
import Dropdown from "../components/elements/Dropdown";

import { ReactComponent as CloseBtnSvg } from "../assets/svg/close.svg";
import { ReactComponent as RightArrowSvg } from "../assets/svg/right.svg";

import { ageArray, servicesArray, nationalities } from "../utils/data";
import { http } from "../helpers/http.helper";
import CommonTopBar from "../components/layouts/CommonTopBar";
import { LogoImage } from "../assets/images";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "../components/layouts/Navigation";
import FooterSection from "../components/layouts/FooterSection";
import { Helmet } from "react-helmet";

const Home = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [ads, setAds] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);

  const maxPageButtons = 5;
  const halfMaxButtons = Math.floor(maxPageButtons / 2);
  const startPage = Math.max(1, page - halfMaxButtons);
  const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
  const [warningShowed, setWarningShowed] = React.useState(false);
  /*filter*/
  const [selectedAge, setSelectedAge] = React.useState("");
  const [selectedServices, setSelectedServices] = React.useState([]);
  const [selectedNationality, setSelectedNationality] = React.useState("");
  /*end filter*/
  const [openFilters, setOpenFilters] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getAds = async () => {
    try {
      const { data } = await http.get(
        `/pub/ads?page=${page}&limit=${12}&age=${selectedAge}&services=${selectedServices.join(
          ","
        )}&nationality=${selectedNationality}`
      );
      setAds(data.ads);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    getAds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, selectedAge, selectedServices, selectedNationality]);

  React.useEffect(() => {
    const getClientIp = async () => {
      try {
        await http.get("/pub/set-today-view");
      } catch (error) {
        console.log(error);
      }
    };
    getClientIp();

    if (!localStorage.getItem("warningShowed")) {
      setWarningShowed(false);
    } else {
      setWarningShowed(true);
    }
  }, []);

  return (
    <>
      {/* {!warningShowed && (
        <div className="warning-message">
          <div className="content">
            <img src={LogoImage} alt="" />
            <p>
              privé.xyz, including all webpages, links and images , displays sexually
              explicit material. Only consenting adults are authorized beyond this page. If you are
              a minor (under the age of 18 years or 21 years where 18 isn't the legal age of
              majority), if sexually explicit material offends you or if it's illegal to view such
              material in your community, you MUST leave this site by clicking "LEAVE" below. By
              clicking "ENTER", you agree to the statement at the bottom of this page.
            </p>
            <p>
              Privé has a zero tolerance policy for child pornography or minors advertising
              or utilizing our site. I agree to report any illegal services or activities which
              violate Terms of Use. I also agree to report suspected exploitation of minors and/or
              human trafficking to the appropriate authorities.
            </p>
            <h1>BY ENTERING privé.xyz YOU AGREE THAT:</h1>
            <ul>
              <li>
                you are an adult in your community and are at least 18 years old (21 in some
                countries)
              </li>
              <li>you agree with terms and conditions</li>

              <li>
                you agree that sexually explicit material is not deemed to be obscene or illegal in
                your community
              </li>

              <li>you accept full responsibility for your own actions</li>

              <li>you agree to the use of cookies</li>
            </ul>
            <h1>YOU HAVE READ THIS AGREEMENT, UNDERSTOOD IT AND AGREE TO BE BOUND BY IT.</h1>
            <button
              onClick={() => {
                localStorage.setItem("warningShowed", true);
                setWarningShowed(true);
              }}
            >
              Enter
            </button>
            <button
              onClick={() => {
                window.location.href = "https://www.google.com";
              }}
            >
              Leave
            </button>
          </div>
        </div>
      )} */}
      {/* {selectedLink && <CommonTopBar selectedCountry={selectedLink.city} />} */}
      {/* {selectedLink && (
        <CommonTopBar selectedCountry={selectedLink.metaTitle} />
      )} */}
      <Helmet>
        <meta charSet="utf-8" />
        <title> {`Privé - Home`} </title>
        <meta name="title" content={`Privé - Home`} />

        {/* <!-- Open Graph Meta Tags --> */}
        <meta property="og:title" content={`Privé - Home`} />

        <meta property="og:image" content={LogoImage} />
        <meta property="og:url" content={`https://privé.xyz/top-50`} />
        <meta property="og:type" content="website" />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={`Privé - Home`} />

        <meta name="twitter:image" content={LogoImage} />
        <meta name="twitter:url" content={`https://privé.xyz/top-50`} />

        <link rel="canonical" href={`https://privé.xyz/top-50`} />
      </Helmet>
      <CommonTopBar selectedCountry="Escorts" />
      <main>
        {/* sidebar */}
        <div className="side-bar"></div>
        {/* sidebar */}

        <div className="main-content">
          <div className="main-content-title-wrapper">
            <div className="main-content-title-container">
              {/* {selectedLink && <h1 className="selected-country-name">{selectedLink.city}</h1>} */}
              {/* {selectedLink && <h1 className="selected-country-name">{selectedLink.metaTitle}</h1>} */}
              <h1 className="selected-country-name">Escorts</h1>
              {/* <Navigation /> */}
              <button onClick={() => setOpenFilters(true)}>Filter</button>
            </div>
          </div>
          <FilterWrapper state={openFilters}>
            <h1>Filter</h1>
            <button onClick={() => setOpenFilters(false)}>
              <CloseBtnSvg />
            </button>

            {/* {mobileLinks.length > 0 && (
              <Dropdown
                data={mobileLinks}
                value={selectedLink?.city}
                handler={handleClickMobileLink}
                placeholder="City"
                className={"country-picker-dropdown"}
              />
            )} */}
            <Dropdown
              data={nationalities}
              value={selectedNationality}
              handler={setSelectedNationality}
              placeholder="Nationality"
            />
            <Dropdown
              data={ageArray}
              value={selectedAge}
              handler={setSelectedAge}
              placeholder="Select age"
            />
            <DropdownWithCheckbox
              data={servicesArray}
              setSelectedArrayHandler={setSelectedServices}
              selectedArray={selectedServices}
              placeholder="Services"
            />
          </FilterWrapper>
          <div className="escort-card-wrapper with-countries">
            <div>
              <div className="escort-card-container">
                <div class="escort-card-container partners">
                  <div class="partner-card" style={{ padding: "20px" }}>
                    <a
                      href="https://www.eurogirlsescort.com"
                      target="_blank"
                      rel="dofollow noreferrer"
                      title="EuroGirlsEscort.com"
                    >
                      <img
                        src="https://www.eurogirlsescort.com/dist/images/banners/468X60.jpg"
                        alt="EuroGirlsEscort.com"
                        title="EuroGirlsEscort.com"
                      />
                    </a>
                  </div>
                  <div class="partner-card" style={{ padding: "20px" }}>
                    <div
                      style={{
                        padding: 0,
                        margin: 0,
                        overflow: "hidden",
                        fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        fontWeight: "bold",
                        width: "244px",
                        height: "60px",
                        display: "block",
                        position: "relative",
                        color: "black",
                        backgroundColor: "black",
                      }}
                    >
                      <a
                        target="_blank"
                        href="https://massagerepublic.com/female-escorts-in-dubai/pornstar-anastasia-doll-french"
                        title="Porn Star Anastasia Doll"
                        style={{
                          padding: 0,
                          margin: 0,
                          overflow: "hidden",
                          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                        }}
                      >
                        <img
                          src="//static.massagerepublic.com/b/mr_banner244x60.jpg"
                          alt="Dubai Escorts - Massage Republic"
                          style={{
                            padding: 0,
                            margin: 0,
                            overflow: "hidden",
                            display: "block",
                            position: "relative",
                            width: "244px",
                            height: "60px",
                            backgroundColor: "black", // Removed !important
                          }}
                        />
                        <span
                          style={{
                            padding: "0",
                            margin: "0",
                            overflow: "hidden",
                            fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                            fontWeight: "bold",
                            position: "absolute",
                            top: "0",
                            left: "0",
                            textDecoration: "none",
                            width: "150px",
                            textAlign: "center",
                            lineHeight: "1.2em",
                            display: "block",
                            color: "black !important",
                            fontSize: "24px !important",
                          }}
                        >
                          Featured Escort
                        </span>
                      </a>
                      <a
                        target="_blank"
                        href="https://massagerepublic.com/female-escorts-in-dubai"
                        title="Shemales Dubai"
                        style={{
                          padding: "0",
                          margin: "0",
                          overflow: "hidden",
                          fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif",
                          textShadow: "0 1px 1px rgba(0,0,0,0.8)",
                          position: "absolute",
                          textDecoration: "underline",
                          right: "6px",
                          bottom: "12px",
                          lineHeight: "1",
                          fontWeight: "normal",
                          width: "86px",
                          textAlign: "right",
                          color: "white", // "!important" is not supported in inline styles
                          fontSize: "12px",
                        }}
                      >
                        Dubai escorts
                      </a>
                    </div>
                  </div>
                </div>
                {ads.map((ad) => (
                  <EscortCard key={ad._id} ad={ad} />
                ))}

                {/* pagination */}
                {totalPages > 1 && (
                  <div className="pagination home">
                    {startPage > 1 && <button onClick={() => setPage(startPage - 1)}>Prev</button>}
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                      <button
                        key={startPage + index}
                        onClick={() => {
                          setPage(startPage + index);

                          window.scrollTo({ top: 0, behavior: "smooth" });
                        }}
                        className={startPage + index === page ? "active" : ""}
                      >
                        {startPage + index}
                      </button>
                    ))}
                    {endPage < totalPages && (
                      <button onClick={() => setPage(endPage + 1)}>Next</button>
                    )}
                  </div>
                )}
                {/* pagination */}
              </div>
            </div>
          </div>
          {/* <FooterSection selectedLink={selectedLink} links={links} /> */}
          <FooterSection />
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Home;
